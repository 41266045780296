export const FULL_ACCESS = "FULL_ACCESS";
export const DASHBOARD_MENU = "DASHBOARD_MENU";
export const PACKAGES_MENU = "PACKAGES_MENU";
export const ACCOUNTS_MENU = "ACCOUNTS_MENU";
export const PAYERS_MENU = "PAYERS_MENU";
export const FAMILIES_MENU = "FAMILIES_MENU";
export const BANK_CARDS_MENU = "BANK_CARDS_MENU";
export const PAYMENT_GATEWAY = "PAYMENT_GATEWAY";
export const USERS_MENU = "USERS_MENU";
export const ADMINS_TAB = "ADMINS_TAB";
export const USERS_TAB = "USERS_TAB";
export const FACTORS_MENU = "FACTORS_MENU";
export const ARTICLE_MENU = "ARTICLE_MENU";
export const SETTING_MENU = "SETTING_MENU";
export const SEO_SETTING_MENU = "SEO_SETTING_MENU";
export const REDIREDT_LINK_MENU = "REDIREDT_LINK_MENU";
export const ANNOUNCEMENTS_MENU = "ANNOUNCEMENTS_MENU";
export const SALES_AGENT_MENU = "SALES_AGENT_MENU";
export const NOTIFICATION_HISTORY_MENU = "SALES_AGENT_MENU";
export const DISCOUNT_CODE_MENU = "DISCOUNT_CODE_MENU";
export const MONITORING_MENU = "MONITORING_MENU"
export const ItemsMenu = [
  { url: '/sales-agent/cutomer', privilege: undefined, label: 'فروش به مشتری', roles: ['MASTER_SALES_AGENT', 'SALES_AGENT'] },
  { url: '/sales-agent/history', privilege: undefined, label: 'تاریخچه ی فروش', roles: ['MASTER_SALES_AGENT', 'SALES_AGENT'] },
  { url: '/sales-agent/agents', privilege: undefined, label: 'نمایندگان', roles: ['MASTER_SALES_AGENT'] },
  { url: '/sales-agent/agent-orders', label: 'سفارشات نمایندگان', roles: ['MASTER_SALES_AGENT'] },
  // { url: '/sales-agent/orders', label: 'سفارشات', roles: ['MASTER_SALES_AGENT'] },
  { url: '/sales-agent/withdrawal', label: 'تسویه حساب ها', roles: ['MASTER_SALES_AGENT'] },
  { url: '/dashboard', label: 'داشبورد', privilege: DASHBOARD_MENU, roles: ['ADMIN'] },
  { url: '/packages', label: 'پکیج ها', privilege: PACKAGES_MENU, roles: ['ADMIN'] },
  { url: '/accounts', label: 'اکانت ها', privilege: ACCOUNTS_MENU, roles: ['ADMIN'] },
  { url: '/payers', label: 'پرداخت کننده ها', privilege: PAYERS_MENU, roles: ['ADMIN'] },
  { url: '/family', label: 'فمیلی ها', privilege: FAMILIES_MENU, roles: ['ADMIN'] },
  { url: '/links', label: 'لینک ها', privilege: FAMILIES_MENU, roles: ['ADMIN'] },
  { url: '/users', label: 'کاربران', privilege: USERS_MENU, roles: ['ADMIN'] },
  { url: '/members', label: 'اعضا', privilege: USERS_MENU, roles: ['ADMIN'] },
  { url: '/orders', label: 'فاکتورها', privilege: FACTORS_MENU, roles: ['ADMIN'] },
  { url: '/bank-cards', label: 'کارت های بانکی', privilege: BANK_CARDS_MENU, roles: ['ADMIN'] },
  { url: '/payment-gateway', label: 'درگاه پرداخت', privilege: PAYMENT_GATEWAY, roles: ['ADMIN'] },
  { url: '/sales-agent', label: 'مدیران فروش', privilege: SALES_AGENT_MENU, roles: ['ADMIN'] },
  { url: '/discount-code', label: 'کد تخفیف', privilege: DISCOUNT_CODE_MENU, roles: ['ADMIN'] },
  { url: '/redirect-links', label: 'لینک های ارجاعی', privilege: REDIREDT_LINK_MENU, roles: ['ADMIN'] },
  { url: '/monitoring', label: 'مانیتورینگ', privilege: MONITORING_MENU, roles: ['ADMIN'] },
  // { url: '/user-notification-history', label: 'پیامک های ارسالی', privilege: NOTIFICATION_HISTORY_MENU, roles: ['ADMIN'] },
  { url: '/article', label: 'محتوا', privilege: ARTICLE_MENU, roles: ['ADMIN'] },
  { url: '/article-category', label: 'دسته بندی مقالات', privilege: ARTICLE_MENU, roles: ['ADMIN'] },
  { url: '/FAQ', label: 'سوالات متداول', privilege: ARTICLE_MENU, roles: ['ADMIN'] },
  // { url: '/announcements', label: 'اعلانات', privilege: ANNOUNCEMENTS_MENU,roles:['ADMIN'] },
  // { url: '/seo-settings', label: 'تنظیمات سئو', privilege: SEO_SETTING_MENU,roles:['ADMIN'] },
  { url: '/settings', label: 'تنظیمات', privilege: SETTING_MENU, roles: ['ADMIN'] },
]
