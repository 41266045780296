import React from "react";
import { Backdrop, CircularProgress, Typography } from "@mui/material";

const FullPageLoader: React.FC<{ message?: string; isLoading: boolean }> = ({
  message,
  isLoading,
}) => {
  return (
    <Backdrop
      sx={{
        color: "#000",
        background: "#f7f8f9",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: "flex",
        flexDirection: "column",
      }}
      open={isLoading}
    >
      <CircularProgress size={'60px'} color="inherit" />
      {message && (
        <Typography
          variant="h6"
          sx={{ marginTop: 2, textAlign: "center", color: "inherit" }}
        >
          {message}
        </Typography>
      )}
    </Backdrop>
  );
};

export default FullPageLoader;